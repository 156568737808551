import { useEffect, useState } from 'react';

const getPathname = () => {
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    const url = location.pathname;
    setPathname(url);
  }, []);

  return pathname;
};

export default getPathname;
