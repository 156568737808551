export const BREAKPOINTS = {
  MOBILE: 'screen and (max-width: 768px)',
  TABLET: 'screen and (max-width: 1024px)',
  DESKTOP: 'screen and (min-width: 1025px)',
  // testing new breakpoints
  MOBILESMALL: 'screen and (max-width: 375px)',
  TABLETMEDIUM: 'screen and (max-width: 900px)',
  DESKTOPLARGE: 'screen and (min-width: 1440px)',
};

export enum COLORS {
  ORANGE = '#EA7927',
  WHITE = '#fff',
  BEIGE = '#F8F1DF',
  LIGHT_BEIGE = '#FDFAF4',
  DARK_GRAY = '#999',
  BLUE = '#F2F6FC',
  BLACK = '#000',
  LIGHT_GRAY = '#E0E0E0',

  // New colors which are TBD site wide
  NEW_BEIGE = '#EFE9E0',
  NEW_ORANGE = '#EA7927',
  DARK_BLUE = '#323F54',
  NEW_LIGHT_BEIGE = '#E3E2DD',
  GREEN = '#526333',
  LIGHT_BLUE = '#C2CBD5',
  SECONDARY_GRAY = '#D6D5D8',

  // hover states
  HOVER_BLACK = '#7F7F7F',
  HOVER_ORANGE = '#BB611F',
}
