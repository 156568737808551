import React, { useState, createContext } from 'react';

export const ToggleContext = createContext(null);

const ToggleProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <ToggleContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
        {children}
      </ToggleContext.Provider>
    </>
  );
};

const mobileNavProvider = ({ element }) => (
  <ToggleProvider>{element}</ToggleProvider>
);

export default mobileNavProvider;
