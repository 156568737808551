import React, { useContext } from 'react';
import styled from '@emotion/styled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { BREAKPOINTS, COLORS } from '../../ui/variables';
import ParagraphLarge from '../../ui/ParagraphLarge';
import { ToggleContext } from '../header/mobileNavProvider';

const Wrapper = styled.div<{ menuOpen: boolean }>`
  @media ${BREAKPOINTS.TABLET} {
    display: ${props => (props.menuOpen ? 'none' : 'block')};
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${COLORS.ORANGE};
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: ${COLORS.HOVER_ORANGE};
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TextWrapper = styled.div`
  color: ${COLORS.WHITE};
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled(ParagraphLarge)`
  margin-bottom: 0;
  margin-right: 6px;
  width: fit-content;
  color: inherit;
  @media ${BREAKPOINTS.MOBILE} {
    text-align: center;
  }
`;

const Code = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-position: under;
`;

interface Props {
  displayBanner?: boolean;
  text: string;
  couponCode: string;
}

const OfferBanner = ({ text, couponCode }: Props) => {
  const { isMenuOpen } = useContext(ToggleContext);

  return (
    <Wrapper menuOpen={isMenuOpen}>
      <a
        href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup?coupon=${couponCode}`}
      >
        <Container>
          <TextWrapper>
            <Text>
              <ReactMarkdown>{text}</ReactMarkdown>
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Code>Use code {couponCode}</Code>
              <ChevronRightIcon fontSize='small' />
            </div>
          </TextWrapper>
        </Container>
      </a>
    </Wrapper>
  );
};

export default OfferBanner;
