import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import UTMBanner from './UTMBanner';

interface Props {
  children: ReactNode;
}

const BannerWrapper = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query UTMs {
      allContentfulPagesUtmBanner {
        nodes {
          utmCampaignName
          displayBanner
          promotionalText {
            promotionalText
          }
          couponCode
        }
      }
    }
  `);
  const bannerObjectsData = data.allContentfulPagesUtmBanner.nodes;
  return (
    <>
      <UTMBanner bannerObjectsData={bannerObjectsData} />
      {children}
    </>
  );
};

export default BannerWrapper;
