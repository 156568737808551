// Body Large

import styled from '@emotion/styled';
import { COLORS } from './variables';

const ParagraphLarge = styled.p`
  color: ${COLORS.BLACK};
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
`;

export default ParagraphLarge;
