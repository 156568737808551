import { useEffect, useState } from 'react';

export function getUTMCampaign() {
  const [utmCampaign, setUtmCampaign] = useState<string>('');

  useEffect(() => {
    const url = window.location.search;
    const queryValue = new URLSearchParams(url).get('utm_campaign');
    setUtmCampaign(queryValue);
  }, []);

  return utmCampaign;
}
