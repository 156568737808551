/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import mobileNavProvider from './src/components/header/mobileNavProvider';
import BannerWrapper from './src/components/BannerWrapper';

export const wrapRootElement = mobileNavProvider;

export const wrapPageElement = ({ element, props }) => {
  return <BannerWrapper {...props}>{element}</BannerWrapper>;
};
