import React, { useEffect, useState } from 'react';

import OfferBanner from './locations/OfferBanner';
import { getUTMCampaign } from '../utils/getUTMCampaign';
import getPathname from '../utils/getPathname';

interface BannerObject {
  utmCampaignName: string;
  displayBanner: boolean;
  promotionalText: any;
  couponCode: string;
}

interface Props {
  bannerObjectsData: BannerObject[];
}

function UTMBanner({ bannerObjectsData }: Props) {
  const [showBanner, setShowBanner] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  // TO INVESTIGATE: why state variable not working
  // const [bannerData, setBannerData] = useState(null);

  const utmCampaignValue = getUTMCampaign();
  const pathname = getPathname();

  const findObjByCampaignName = (utmArray: BannerObject[]) => {
    const objectFound = utmArray.find(
      (obj: BannerObject) => obj.utmCampaignName === utmCampaignValue
    );

    return objectFound || null;
  };

  const bannerData = findObjByCampaignName(bannerObjectsData);

  useEffect(() => {
    if (bannerData !== null) {
      setShowBanner(true);
      sessionStorage.setItem(
        'sd_utm',
        JSON.stringify({
          isUTMPresent: true,
          utm_campaign: bannerData.utmCampaignName,
          text: bannerData.promotionalText.promotionalText,
          coupon: bannerData.couponCode,
          displayBanner: bannerData.displayBanner,
        })
      );
    } else if (bannerData === null) {
      setShowBanner(true);
      setSessionData(JSON.parse(sessionStorage.getItem('sd_utm')));
    }
  }, [bannerData]);

  useEffect(() => {
    if (sessionData?.isUTMPresent) {
      setShowBanner(true);
    }
  }, []);

  useEffect(() => {
    if (pathname.includes('/lp/')) setShowBanner(false);
  }, [pathname]);

  return (
    <>
      {showBanner &&
        (bannerData?.displayBanner || sessionData?.displayBanner) && (
          <OfferBanner
            text={
              bannerData?.promotionalText.promotionalText || sessionData?.text
            }
            couponCode={bannerData?.couponCode || sessionData?.coupon}
          />
        )}
    </>
  );
}

export default UTMBanner;
